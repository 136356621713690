












































































































































































































import Vue from 'vue'
import axios from 'axios'

export class SearchForm {
  public searchW: string | null;
  constructor () {
    this.searchW = null
  }
}

export interface Order {
  id: number | null;
  name: string | null;
  maker: string | null;
  orderValue: number | null;
}

export interface Part {
  id: number | null;
  name: string | null;
  maker: string | null;
  value: number | null;
  bunrui: string | null;
  creDt: string | null;
  updateDt: string | null;
}

export class OrderForm {
  public company: string | null;
  public name: string | null;
  public email: string | null;
  public tel: string | null;
  public fax: string | null;
  public free: string | null;
  public parts: string | null;
  public act: number | null;
  constructor () {
    this.company = null
    this.name = null
    this.email = null
    this.tel = null
    this.fax = null
    this.free = null
    this.parts = ''
    this.act = 3
  }
}

export default Vue.extend({
  name: 'Search',
  data: function () {
    return {
      loading: false,
      dialog: false,
      message: '',
      dialog_error: false,
      errorMessage: '',
      searchForm: new SearchForm(),
      orderForm: new OrderForm(),
      emailRules: [
        (value: string) => !!value || 'メールは必須項目です。',
        (value: string) => /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(value) || '正しいメールアドレスの書式で入力してください。'
      ],
      companyRules: [
        (value: string) => !!value || '会社名は必須項目です。'
      ],
      nameRules: [
        (value: string) => !!value || '担当者名は必須項目です。'
      ],
      isValid: true,
      headers: [
        { text: '', value: 'id', sortable: false },
        { text: '型名', value: 'name' },
        { text: 'メーカー', value: 'maker' },
        { text: '在庫', value: 'value' },
        { text: '', value: 'action', sortable: false, align: 'end' }
      ],
      parts: new Array<Part>(),
      order_headers: [
        { text: '型名', value: 'name' },
        { text: 'メーカー', value: 'maker' },
        { text: '注文数', value: 'orderValue' },
        { text: '', value: 'action', sortable: false, align: 'end' }
      ],
      orders: new Array<Order>()
    }
  },
  mounted: async function () {
    // this.form.resetValidation()
    // レイアウト用テストデータ
    // this.parts.push(
    //   { id: 59178, maker: 'テストメーカー', name: '(A)TLNC 3.15A', value: 15, bunrui: null, creDt: '2021-08-16 03:02:35', updateDt: '2021-08-16 03:04:03' }
    // )
    // this.searchForm.searchW = this.$route.query.searchW ? String(this.$route.query.searchW) : null
    // if (this.searchForm.searchW === null) return
    // this.searchForm.searchW.replace('\n', ' ')
    try {
      const response = await axios.get('/v1/product_search.php?focus_flg=1')
      this.parts = response.data.data
    } catch (e) {
      // console.log('電子パーツの取得に失敗：', this.parts)
      // console.error(e)
    }
  },
  computed: {
    form (): Vue & { validate: () => boolean } {
      return this.$refs.form as Vue & { validate: () => boolean }
    }
  },
  methods: {
    async getParts () {
      try {
        // if (this.searchForm.searchW === null) return
        this.loading = true
        // this.searchForm.searchW.replace('\n', ' ')
        const response = await axios.get('/v1/product_search.php?focus_flg=1')
        this.parts = response.data.data
        this.loading = false
      } catch (e) {
        // console.log('電子パーツの取得に失敗：', this.parts)
        // console.error(e)
      }
    },
    deleteOrder (deleteId: number) {
      if (deleteId === null) return
      this.orders = this.orders.filter((order: Order) => {
        return (order.id !== deleteId)
      })
    },
    addOrder (addId: number) {
      if (addId === null) return
      // すでに登録されていたら登録しない。
      if (this.orders.findIndex(({ id }) => id === addId) !== -1) return

      const part: Part[] = this.parts.filter((part: Part) => part.id === addId)
      if (part) {
        this.orders.push({ id: part[0].id, maker: part[0].maker, name: part[0].name, orderValue: 1 })
      }
    },
    async postForm () {
      try {
        if (this.orders.length === 0) return
        if (!this.form.validate()) {
          this.errorMessage = '必須項目が入力されていません。'
          this.dialog_error = true
        } else {
          let freeWord = ''
          this.orders.forEach(function (order: Order) {
            freeWord += ('型名:' + order.name + '  メーカー:' + order.maker + '  注文数:' + order.orderValue + '\n')
          })
          this.orderForm.parts += freeWord
          const response = await axios.post('/v1/product_search_send.php', this.orderForm)

          if (response.data.message) {
            this.message = response.data.message
            this.dialog = true
            // return
          }
        }
      } catch (e) {
        // console.log('フォームの送信に失敗：', this.orderForm)
        // console.error(e)
      }
    }
  }
})
